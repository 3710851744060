"use client";

import { init, tx, id } from "@instantdb/react";
import { useEffect, useState } from "react";
import { formatDate, formatDateLong, formatTime } from "./utils";
import {
  Backspace,
  Calendar,
  Island,
  Notification,
  Timer,
} from "@phosphor-icons/react";
import TimeTracker from "./TimeTracker";

// ID for app: workbook
const APP_ID = "88c74f08-ba4e-4ea8-9158-0d77feb9a6ef";
const db = init({ appId: APP_ID, devtool: false });

function LogForm({ log, setSelectedLog }) {
  const [value, update] = useState("");
  const [haveUpdate, setHaveUpdate] = useState(false);

  useEffect(() => {
    update(log?.text ?? "");
  }, [log]);

  useEffect(() => {
    const handleKeyDown = (event) => {
      // Detect CMD (or CTRL on Windows) + S
      if ((event.metaKey || event.ctrlKey) && event.key === "s") {
        event.preventDefault();
        if (!haveUpdate) return;
        if (log === null) {
          const log = {
            text: value,
            done: false,
            createdAt: Date.now(),
          };
          const id_ = id();
          db.transact(tx.logs[id_].update(log));
          setSelectedLog({ ...log, id: id_ });
        } else {
          db.transact(
            tx.logs[log.id].update({
              ...log,
              text: value,
            })
          );
        }
        setHaveUpdate(false);
      }
    };

    // Add event listener
    window.addEventListener("keydown", handleKeyDown);

    // Cleanup event listener
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  const [tick, tock] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      tock(!tick);
      if (!haveUpdate) return;
      if (log === null) {
        const log = {
          text: value,
          done: false,
          createdAt: Date.now(),
        };
        const id_ = id();
        db.transact(tx.logs[id_].update(log));
        setSelectedLog({ ...log, id: id_ });
      } else {
        db.transact(
          tx.logs[log.id].update({
            ...log,
            text: value,
          })
        );
      }
      setHaveUpdate(false);
    }, 1e3);
    return () => clearTimeout(timer);
  }, [tick]);

  return (
    <div style={{ padding: "12px" }}>
      <div
        style={{
          fontFamily: "Manrope",
          fontWeight: "600",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          marginBottom: "10px",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          {formatDateLong(log?.createdAt ?? Date.now())}
          <Backspace
            weight={"bold"}
            style={{ color: "red", paddingLeft: "10px", cursor: "pointer" }}
            onClick={() => {
              db.transact(tx.logs[log.id].delete());
              setSelectedLog(null);
              update("");
            }}
          />
        </div>
        <span style={{ fontSize: "12px", marginTop: "6px" }}>
          {formatTime(log?.createdAt ?? Date.now())}
        </span>
      </div>
      <textarea
        autoFocus
        style={{
          border: "none",
          outline: "none",
          resize: "none",
          fontFamily: "JetBrains Mono",
          // fontWeight: "400",
        }}
        type="text"
        value={value}
        rows={40}
        cols={120}
        onChange={(e) => {
          e.preventDefault();
          setHaveUpdate(true);
          update(e.target.value);
        }}
      />
    </div>
  );
}

const Taskbar = ({ mode, setMode }) => {
  return (
    <div
      style={{
        position: "fixed",
        width: "100%",
        bottom: "0",
        backgroundColor: "#FAC9B8",
        color: "white",
        height: "40px",
        display: "flex",
        alignItems: "center",
        paddingLeft: "20px",
      }}
    >
      <Island
        onClick={() => {
          db.auth.signOut();
        }}
        style={{ cursor: "pointer", color: "#235789", paddingRight: "10px" }}
        size={30}
      />
      <Calendar
        onClick={() => {
          console.log(mode, "calendar");
          if (mode === "calendar") {
            setMode("notes");
          } else setMode("calendar");
        }}
        style={{ cursor: "pointer", color: "#235789", paddingRight: "10px" }}
        size={30}
      />
      <Timer
        onClick={() => {
          console.log(mode, "timer");
          if (mode === "timer") {
            setMode("notes");
          } else setMode("timer");
        }}
        style={{ cursor: "pointer", color: "#235789", paddingRight: "10px" }}
        size={30}
      />
    </div>
  );
};

function LogList({ logs, selectedLog, setSelectedLog }) {
  return (
    <div
      style={{
        paddingLeft: "10px",
        paddingTop: "20px",
        fontFamily: "Manrope",
        fontWeight: "600",
      }}
    >
      {logs.map((log) => (
        <div key={log.id} style={{ marginBottom: "20px" }}>
          <div
            style={{
              display: "flex",
              // justifyContent: "space-around",
              alignItems: "center",
            }}
          >
            <Notification
              weight="bold"
              style={{
                color:
                  selectedLog?.createdAt == log.createdAt ? "#0a0" : "black",
                fontSize: "1.3em",
                paddingRight: '12px'
              }}
            />
            <span
              onClick={() => {
                setSelectedLog(
                  selectedLog?.createdAt == log.createdAt ? null : log
                );
              }}
              style={{
                cursor: "pointer",
                paddingRight: "18px",
                fontSize: "0.9em",
              }}
            >
              {formatDate(log.createdAt)}
            </span>
          </div>
        </div>
      ))}
    </div>
  );
}

const AccessPanel = () => {
  const [selectedLog, setSelectedLog] = useState(null);
  const [mode, setMode] = useState("notes"); // "calendar", "time"

  const { isLoading, error, data } = db.useQuery({ logs: {} });
  if (isLoading) return <div>Fetching data...</div>;
  if (error) return <div>Error fetching data: {error.message}</div>;

  const { logs } = data;
  return (
    <div>
      <div
        style={{
          display: "grid",
          gridTemplateColumns: "1fr 11fr",
          height: "100vh",
        }}
      >
        <div
          style={{
            backgroundColor: "rgb(248, 248, 248",
            height: "100%",
            minWidth: "220px",
            // borderRight: '1px solid #fff'
          }}
        >
          <LogList
            logs={logs}
            selectedLog={selectedLog}
            setSelectedLog={setSelectedLog}
          />
        </div>
        <div style={{ backgroundColor: "white", height: "100%" }}>
          {mode == "timer" ? (
            <TimeTracker db={db} />
          ) : (
            <LogForm log={selectedLog} setSelectedLog={setSelectedLog} />
          )}
        </div>
      </div>
      <Taskbar mode={mode} setMode={setMode} />
    </div>
  );
};

export default AccessPanel;
