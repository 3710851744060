"use client";

import React, { useState } from "react";
import { init } from "@instantdb/react";
import { GoogleOAuthProvider, GoogleLogin } from "@react-oauth/google";
import AccessPanel from "./AccessPanel";

const APP_ID = "88c74f08-ba4e-4ea8-9158-0d77feb9a6ef";

const db = init({ appId: APP_ID });
const GOOGLE_CLIENT_ID =
  "62344028429-vtpidftc1lu7sl1csqlo3qcs9ou7rsae.apps.googleusercontent.com";

// Use the google client name in the Instant dashboard auth tab
const GOOGLE_CLIENT_NAME = "google-web";

const Auth = () => {
  const { isLoading, user, error } = db.useAuth();
  if (isLoading) {
    return <div>Loading...</div>;
  }
  if (error) {
    return <div>Uh oh! {error.message}</div>;
  }
  if (user) {
    return <AccessPanel />;
  }

  return <Login />;
};

function Login() {
  const [nonce] = useState(crypto.randomUUID());

  return (
    <GoogleOAuthProvider clientId={GOOGLE_CLIENT_ID}>
      <GoogleLogin
        nonce={nonce}
        onError={() => alert("Login failed")}
        onSuccess={({ credential }) => {
          db.auth
            .signInWithIdToken({
              clientName: GOOGLE_CLIENT_NAME,
              idToken: credential,
              // Make sure this is the same nonce you passed as a prop
              // to the GoogleLogin button
              nonce,
            })
            .catch((err) => {
              alert("Uh oh: " + err.body?.message);
            });
        }}
      />
    </GoogleOAuthProvider>
  );
}

export default Auth;
