import React, { useState } from "react";
import { tx, id } from "@instantdb/react";

const TimeTracker = ({ db }) => {
  const [newProjectLabel, setNewProjectLabel] = useState(null);

  if (!db) return null;
  const { isLoading, error, data } = db.useQuery({ timeTrackProjects: {} });
  if (isLoading) return <div>Fetching data...</div>;
  if (error) return <div>Error fetching data: {error.message}</div>;
  const { timeTrackProjects } = data;

  const createProject = () => {
    if (newProjectLabel == null) return;
    const project = {
      label: newProjectLabel,
      entries: [],
    };
    const id_ = id();
    db.transact(tx.timeTrackProjects[id_].update(project));
    setNewProjectLabel(null);
  };

  const handleProjectClick = (id) => {
    const project = timeTrackProjects[id];
    if (!project) return;

    const entry = project.entries[project.entries.length - 1];
    if (!entry || entry.end) {
      db.transact(
        tx.timeTrackProjects[project.id].update({
          ...project,
          entries: [...project.entries, { start: Date.now(), end: null }],
        })
      );
      return;
    }

    db.transact(
      tx.timeTrackProjects[project.id].update({
        ...project,
        entries: project.entries.map((entry, index) => {
          if (index === project.entries.length - 1 && entry.end === null) {
            return { ...entry, end: Date.now() };
          }
          return entry;
        }),
      })
    );
  };

  return (
    <div>
      <div>
        <input
          type="text"
          value={newProjectLabel ?? ""}
          onChange={(e) => setNewProjectLabel(e.target.value)}
          placeholder="Enter project name"
        />
        <button onClick={createProject}>Create Project</button>
      </div>

      <ul>
        {timeTrackProjects.map((project, index) => (
          <li key={index}>
            <button onMouseDown={() => handleProjectClick(index)}>
              {project.label}
            </button>
            <ul>
              {project.entries.map((entry) => (
                <li>
                  {new Date(entry.start).toLocaleString()} -{" "}
                  {entry.end ? new Date(entry.end).toLocaleString() : ""}
                </li>
              ))}
            </ul>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default TimeTracker;
